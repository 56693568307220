import React from 'react';

import { Button, List, Card } from "antd";
import { Row, Col } from 'antd';
import { Component } from 'react';
import { Layout } from 'antd';
import { Divider } from "antd";

const { Content } = Layout;

const drinkTypes = [
    { key: "wineNew", label: "US Wine", desc: "Discover top wine brands from the United States, showcasing their finest offerings." },
    { key: "spiritsNew", label: "US Spirits", desc: "Explore premium spirits brands crafted in the USA, each with a unique story." },
    { key: "beerNew", label: "US Beer", desc: "Savor a selection of renowned craft beer brands from across the United States." },
    { key: "everythingNew", label: "US Everything", desc: "Browse an extensive range of products from various reputable brands in the USA." },
    { key: "eu", label: "Europe Wine", desc: "Indulge in celebrated wine brands from Europe, known for their rich heritage and quality." },
    { key: "wineNewCa", label: "Canada Wine", desc: "Taste exceptional wines from leading Canadian brands, each offering unique flavors." },
    { key: "wineNewAsia", label: "Asia Wine", desc: "Taste exceptional wines from leading Canadian brands, each offering unique flavors." },
    { key: "spiritsNewAsia", label: "Asia Spirits", desc: "Experience a diverse array of spirits from prominent Asian brands, each with distinct character." },
    { key: "spiritsNewEu", label: "Europe Spirits", desc: "Enjoy a sophisticated selection of spirits from esteemed European brands." },
];

const Head = ({ currentRegion }) => (
    <Row align="middle" style={{ margin: "10px 20px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h1>{currentRegion || 'Not set'}</h1>
            <p style={{ color: "#950016" }}>Your Current Portal</p>
        </Col>
    </Row>
);



class Chooser extends Component {

    constructor(props) {
        super(props);
    }

    getDrinkTypeLabel(key) {
        const drinkType = drinkTypes.find(drink => drink.key === key);
        return drinkType ? drinkType.label : key;
    }
    render() {


        const drinkType = this.getDrinkTypeLabel(this.props.DRINKTYPE);

        return (
            <Layout>

                <Content style={{ margin: "30px" }}>

                    <Head currentRegion={drinkType}></Head>
                    <Divider />
                    <h2>Choose Your Somm.ai Portal</h2>


                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 3,
                            xl: 3,
                            xxl: 3,
                        }}
                        dataSource={drinkTypes}
                        renderItem={(item) => (
                            <List.Item>
                                <Card title={item.label} hoverable>
                                    <Button type="primary" onClick={() => this.props.setDRINKTYPE(item.key)}>Select</Button>

                                </Card>


                            </List.Item>
                        )}
                    />



                </Content>
            </Layout>
        );
    }
}

export default Chooser;